@font-face {
  font-family: "DIN-Medium";
  src: local("DINNextW1G-Medium"),
    url("./fonts/DINNextW1G-Medium.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "DIN-Regular";
  src: local("DINNextW1G-Regular"),
    url("./fonts/DINNextW1G-Regular.otf") format("truetype");
  font-weight: normal;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
}

html {
  font-size: 62.5%; /*1rem = 10px;*/
  min-height: 100%;
}

body {
  overflow: overlay;
  overflow-x: hidden;
  min-height: 100%;
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*Scrollbar Style*/
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  display: initial;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #232426;
  border-radius: 20px;
  border: 3px transparent solid;
  background-clip: padding-box;
}
